/**
 * MUI CSS/JS form helpers module
 * @module lib/forms.py
 */

'use strict';

var jqLite = require('./jqLite');


/**
 * Menu position/size/scroll helper
 * @returns {Object} Object with keys 'height', 'top', 'scrollTop'
 */
function getMenuPositionalCSSFn(wrapperEl, menuEl, selectedRow) {
  var viewHeight = document.documentElement.clientHeight,
      numRows = menuEl.children.length;

  // determine menu height
  var h = parseInt(menuEl.offsetHeight),
      height = Math.min(h, viewHeight);

  // determine row height
  var p = parseInt(jqLite.css(menuEl, 'padding-top')),
      rowHeight = (h - 2 * p) / numRows;

  // determine 'top'
  var top, initTop, minTop, maxTop;

  initTop = -1 * selectedRow * rowHeight;
  minTop = -1 * wrapperEl.getBoundingClientRect().top;
  maxTop = (viewHeight - height) + minTop;

  top = Math.min(Math.max(initTop, minTop), maxTop);

  // determine 'scrollTop'
  var scrollTop = 0,
      scrollIdeal,
      scrollMax;

  if (h > viewHeight) {
    scrollIdeal = top + p + selectedRow * rowHeight;
    scrollMax = numRows * rowHeight + 2 * p - height;
    scrollTop = Math.min(scrollIdeal, scrollMax);
  }

  return {
    'height': height + 'px',
    'top': top + 'px',
    'scrollTop': scrollTop
  };
}


/** Define module API */
module.exports = {
  getMenuPositionalCSS: getMenuPositionalCSSFn
};
